import React from "react";
import { PageProps } from "gatsby";

import { SEO } from "../components/common/seo";
import { AboutPage } from "../components/Layout/AboutPage";

const About: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title="About" />

      <AboutPage />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default About;
