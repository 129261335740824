import React from "react";
// import classNames from "classnames";

import { Link } from "gatsby";
import { Page } from "../Page";
import { Title } from "../../common/Title";
import LiminalSpaceLogo from "../../../images/logos/liminal-space.png";
import AMSLogo from "../../../images/logos/ams.png";
// import WellcomeTrustLogo from "../../../images/logos/wellcome-trust.png";
import UKRILogo from "../../../images/logos/ukri.png";

import styles from "./AboutPage.module.scss";
import { Markdown } from "../../common/Markdown";
// eslint-disable-next-line import/no-webpack-loader-syntax
import AboutText from "!!raw-loader!./AboutText.md";

const LOGOS = [
  {
    link: "https://www.the-liminal-space.com/",
    alt: "The Liminal Space website",
    logo: LiminalSpaceLogo,
  },
  {
    link: "https://acmedsci.ac.uk/",
    alt: "Academy of Medical Sciences website",
    logo: AMSLogo,
  },
  {
    link: "https://www.ukri.org/",
    alt: "UK Research and Innovation website",
    logo: UKRILogo,
  },
];

const AboutPage: React.FC = () => {
  return (
    <Page className={styles.about}>
      <Title tagName="h1">About</Title>

      <Markdown source={AboutText} />

      <ul className={styles.logos}>
        {LOGOS.map(v => {
          return (
            <li key={v.link}>
              <a href={v.link} target="_blank" rel="noreferrer noopener">
                <img src={v.logo} alt={v.alt} />
              </a>
            </li>
          );
        })}
      </ul>

      <p className={styles.meta}>
        Copyright of Liminal Space 2021
        <br />
        <Link to="/privacy">Privacy Policy</Link>
      </p>
    </Page>
  );
};

export { AboutPage };
