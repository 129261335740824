import React from "react";
import classNames from "classnames";

import styles from "./Title.module.scss";

interface TitleProps extends React.HTMLAttributes<HTMLElement> {
  tagName: keyof Pick<
    JSX.IntrinsicElements,
    "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "p"
  >;
  titleStyle?: "heading";
}

const Title: React.FC<TitleProps> = ({
  children,
  className,
  titleStyle = "heading",
  tagName: El = "span",
  ...props
}) => {
  return (
    <El
      className={classNames(styles.title, styles[titleStyle], className)}
      {...props}
    >
      {children}
    </El>
  );
};

export { Title };
